import React from 'react';
import './Container.scss';

const Container = ({children, fullHeight}: {children: any, fullHeight?: boolean}) => {
  return (
    <div className={`Container ${fullHeight && 'Container--fullHeight'}` }>
      {children}
    </div>
  )
}

export default Container;
