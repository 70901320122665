import React, { FunctionComponent } from 'react'
import Container from '../Container/Container';

interface IHeroProps {
  children: any;
  interior?: boolean
}
const Hero: FunctionComponent<IHeroProps> = ({ children, interior }) => (
  <section className={`Hero ${interior ? 'Hero--interior' : ''}`}>
    <Container fullHeight>
      <div className="Hero-container">
        <div className="Hero-content">
          {children}
        </div>
      </div>
    </Container>
  </section>
)

export default Hero
