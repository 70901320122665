import React, { Component } from 'react'
import { Button } from 'semantic-ui-react';
import Container from '../Container/Container';
import { withPrefix } from 'gatsby';
import ContactForm from '../ContactForm/ContactForm';
import { Link } from 'gatsby';

interface HeaderProps {

}

interface HeaderState {
  atTheTop?: boolean;
}

class Header extends Component<HeaderProps, HeaderState> {

  constructor(props: any) {
    super(props);

    this.state = {
      atTheTop: false,
    }

    this.handleOnScroll = this.handleOnScroll.bind(this);
  }

  componentDidMount(): void {
    window.addEventListener('scroll', this.handleOnScroll)
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.handleOnScroll)
  }

  handleOnScroll(): void {
    const { atTheTop } = this.state;
    const scrollPos = window.scrollY;

    window.requestAnimationFrame(() => {
      if (scrollPos >= 5 && !atTheTop) {
        this.setState({ atTheTop: true })
      }

      if (scrollPos < 5 && atTheTop) {
        this.setState({ atTheTop: false })
      }
    })
  }

  render(): JSX.Element {
    const { atTheTop } = this.state;

    return (
      <header className={`HeaderPrimary ${atTheTop && 'HeaderPrimary--filled'}`}>
        <Container>
          <div className="HeaderPrimary-container">
            <div className="HeaderPrimary-logo">
              <Link to="/">
                <img src={withPrefix(`/images/${atTheTop ? 'isync-logo-alt.png' : 'isync-logo.png'}`)} alt="Isync solutions website logo, click to go to the homepage." />
              </Link>
            </div>
            <div className="HeaderPrimary-cta">
              <Link to='/contact' >
                <Button className="green">
                  Contact Us
                </Button>
              </Link>
            </div>
          </div>
        </Container>
      </header>
    )
  }

}

export default Header
