import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './Header/Header';

import Footer from './Footer/Footer';
import '../styles/global.scss'

const Layout = ({ children }) => (
  <Fragment>
    <Header />
    <main>
      {children}
    </main>
    <Footer />
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
    />
  </Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
