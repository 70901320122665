import React from 'react'

import './Portrait.scss';

const Portrait = ({ portrait, alt }: { portrait: string, alt: string }): JSX.Element => (
  <div className="Portrait">
    <img src={portrait} alt={alt} />
  </div>
)

export default Portrait;
