import React, { Fragment, Component } from 'react'
import Container from '../Container/Container';
import Portrait from '../Portrait/Portrait';
import { Button, Icon, Item, Grid, Image as ImageComponent, Embed } from 'semantic-ui-react'
import NetlifyIdentityWidget from "netlify-identity-widget";
import './Footer.scss';

interface FooterProps { }
interface FooterState {
  expanded: boolean;
  windowObject: {} | null;
}

class Footer extends Component<FooterProps, FooterState> {

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      windowObject: null,
    }

    this.expandCompany = this.expandCompany.bind(this);
  }

  expandCompany() {
    this.setState({
      expanded: !this.state.expanded
    })
  }

  handleLogin() {
    NetlifyIdentityWidget.open();
  }

  render() {
    const paragraph = <ImageComponent src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />

    return (
      <Fragment>
        <footer className='Primary'>
          <Container>
            <Grid stackable>
              <Grid.Column width={3}>
                <Portrait portrait='/images/drewhuett.jpeg' alt='Drew Huett, Owner of isync solutions.' />
              </Grid.Column>
              <Grid.Column width={9}>
                <h4>ABOUT THE FOUNDER</h4>
                <h5>Drew Huett</h5>
                <p>
                  <Button circular color='linkedin' icon='linkedin' href='https://www.linkedin.com/in/andrew-huett-b0069620/' />
                  <Button circular color='instagram' icon='instagram' href='https://www.instagram.com/isync.solutions/' />
                </p>
                <p>
                  Drew is a lower voltage expert with over 15 years of technical and sale experience in security, surveillance, access control , audio/video, automation, and lighting control.
                </p>

                <blockquote className="blockquote">
                  As the owner and founder of iSync Solutions, I’m delighted to offer low-voltage electronics to
                  central Arkansas. It’s a fancy way of referring to high-end residential automation, which covers
                  everything from speakers to voice control to custom design work to security systems. We’ve got
                  your home and business covered. <br /><br />

                  We live in the Little Rock area with our teenage son, Drake, our dog, Scooby and cat, Starla,
                  who enjoys picking on the dog a little too much. When we’re not developing iSync, which, to be
                  honest, is most of our time these days, you can find us spending time together at home or
                  floating the river. <br /><br />

                  I can’t wait to help you build the home system that fits your needs and your budget.
                </blockquote>
                <p>
                  {/* <Button onClick={this.expandCompany} className='ButtonLink'>About the Company <Icon name={this.state.expanded ? 'angle up' : 'angle down'} /></Button> */}
                </p>

              </Grid.Column>
              <Grid.Column width={4}>
                <p>iSYNC Solutions</p>
                <p>
                  <a href='tel:5018130821'>501-813-0821</a> <br />
                  <a href='mailto:drewhuett@gmail.com'>drewhuett@gmail.com</a>
                </p>
                {/* <p>
                  <a href="#" onClick={this.handleLogin}>Login</a>
                </p> */}
              </Grid.Column>
            </Grid>
            <div className={`FooterCompany ${this.state.expanded ? 'FooterCompany--active' : ''}`}>
              <h3>About Isync</h3>
              <Grid>
                <Grid.Column width={4}>
                  <ImageComponent src='https://react.semantic-ui.com/images/wireframe/image.png' />
                </Grid.Column>
                <Grid.Column width={12}>
                  <ImageComponent src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
                </Grid.Column>
              </Grid>

              <h3>Location</h3>

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13387.730669424689!2d-96.7646427!3d32.9791782!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfb1a92ef33c6ff88!2sChatime+%26+ZenQ!5e0!3m2!1sen!2sus!4v1548982115846"
                width="350"
                height="250"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen></iframe>

              <h3>Isync Employees</h3>
              <Item.Group link>
                <Item>
                  <Item.Image size='tiny' src='https://react.semantic-ui.com/images/avatar/large/stevie.jpg' />

                  <Item.Content>
                    <Item.Header>Stevie Feliciano</Item.Header>
                    <Item.Description>{paragraph}</Item.Description>
                  </Item.Content>
                </Item>

                <Item>
                  <Item.Image size='tiny' src='https://react.semantic-ui.com/images/avatar/large/veronika.jpg' />

                  <Item.Content>
                    <Item.Header>Veronika Ossi</Item.Header>
                    <Item.Description>{paragraph}</Item.Description>
                  </Item.Content>
                </Item>

                <Item>
                  <Item.Image size='tiny' src='https://react.semantic-ui.com/images/avatar/large/jenny.jpg' />
                  <Item.Content>
                    <Item.Header>Jenny Hess</Item.Header>
                    <Item.Description>{paragraph}</Item.Description>
                  </Item.Content>
                </Item>


              </Item.Group>

            </div>
          </Container>
        </footer>
      </Fragment>
    )
  }
}

export default Footer
